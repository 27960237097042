@import '../../abstracts/variable';

.bootstrap-tagsinput {
  flex: 1;
  padding: 0;
  padding-left: 0.5rem;
  border-radius: 0;
  border-color: $border;
  background-color: $white;
  width: 100%;

  .tag {
    font-size: 0.875rem;
    background-color: var(--primary);
    // color: $white;
    border-radius: 0;
    align-items: center;
    border: 0.0625rem solid $border;
  }

  input[type='text'] {
    flex: 1;
    height: 2.1rem;
    border-radius: 0;
    border-color: $border;
    // max-width: 6.5625rem;
  }

  label {
    margin-bottom: 0.5rem;
  }
}
