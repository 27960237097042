html[dir='rtl'] {
  //footer
  [direction='rtl'] {
    .footer {
      padding-right: 17.1875rem;
      padding-left: 0;

      @include media-breakpoint-down(sm) {
        padding-right: 0;
      }
    }

    &[data-sidebar-style='overlay'] {
      .footer {
        padding-right: 0;
      }
    }

    .menu-toggle {
      .footer {
        padding-right: 3.75rem;
      }
    }

    &[data-container='boxed'] {
      .footer {
        padding-right: 0;
      }
    }

    &[data-sidebar-style='mini']:not([data-layout='horizontal']) {
      .footer {
        padding-right: 3.75rem;
      }
    }

    &[data-sidebar-style='compact']:not([data-layout='horizontal']) {
      .footer {
        padding-right: 9.375rem;
      }
    }
  }
}
