.header-right {
  height: 100%;

  .nav-item {
    height: 100%;
    display: flex;
    align-items: center;
    .nav-link {
      color: $l-ctd;
      font-size: 1.125rem;
    }
  }
  .right-sidebar {
    margin-right: -1.875rem;
    a {
      height: 5rem;
      width: 5rem;
      text-align: center;
      justify-content: center;
      display: flex;
      align-items: center;
      border-left: 0.0625rem solid $light;
    }
  }
  & > li {
    &:not(:first-child) {
      padding-left: 1.25rem;
      @include respond('tab-land') {
        padding-left: 1rem;
      }
      @include respond('phone') {
        padding-left: 0.4rem;
      }
    }
  }
  .notification_dropdown {
    @include respond('phone-land') {
      position: static;
    }
    .nav-link {
      position: relative;
      color: var(--primary);
      border-radius: $radius;
      padding: 0.9375rem;
      line-height: 1;
      @include respond('laptop') {
        padding: 0.625rem;
      }

      i {
        font-size: 1.5rem;
        @include respond('laptop') {
          font-size: 1.125rem;
        }
      }
      svg {
        @include respond('laptop') {
          width: 1.5rem;
          height: 1.5rem;
        }
        @include respond('phone') {
          width: 1.25rem;
          height: 1.25rem;
        }
      }
      .badge {
        position: absolute;
        font-size: 0.625rem;
        border-radius: 50%;
        right: -0.5rem;
        top: -0.25rem;
        font-weight: normal;
        height: 1.875rem;
        width: 1.875rem;
        line-height: 1.125rem;
        text-align: center;
        padding: 0.3125rem;
        font-size: 0.875rem;

        @include respond('laptop') {
          height: 1.25rem;
          width: 1.25rem;
          border-width: 0.125rem;
          line-height: 0.4375rem;
          font-size: 0.5625rem;
        }
        @include respond('phone') {
          height: 1.125rem;
          width: 1.125rem;
          padding: 0;
          line-height: 0.9375rem;
          right: 0rem;
          top: -0.125rem;
        }
      }
    }
    .dropdown-item {
      &:focus,
      &:active {
        a {
          color: $white;
        }
      }
      a {
        color: $dark;
        &:hover {
          text-decoration: none;
        }
      }
    }
  }
  .dropdown-menu {
    border-width: 0;
    box-shadow: 0 0 2.3125rem rgba(8, 21, 66, 0.05);
    @at-root [data-theme-version='dark'] & {
      box-shadow: none;
    }
  }
  //search-area
  .search-area {
    width: 21.25rem;
    .form-control {
      height: 3.5rem;
      background: #f3f3f3;
      border: 0;
    }
    .input-group-text {
      height: 3.5rem;
      border-radius: 1rem;
      background: #f3f3f3;
      padding: 0 1.25rem;
      i {
        font-size: 1.5rem;
      }
    }
    @include custommq($max: 100rem) {
      width: 15.625rem;
    }
    @include respond('tab-land') {
      display: none;
    }
  }
}
.dz-fullscreen {
  #icon-minimize {
    display: none;
  }
  &.active {
    #icon-full {
      display: none;
    }
    #icon-minimize {
      display: inline-block;
    }
  }
}
.notification_dropdown {
  .dropdown-menu-end {
    min-width: 19.375rem;
    padding: 0rem 0 1rem;
    top: 100%;

    .notification_title {
      background: var(--primary);
      color: $white;
      padding: 0.625rem 1.25rem;
      h5 {
        color: $white;
        margin-bottom: 0.1875rem;
      }
    }
    .media {
      width: 2.8125rem;
      height: 2.8125rem;
      font-size: 1.125rem;

      // &:last-child{
      //     border-bottom: 0rem;
      // }
      @at-root [data-theme-version='dark'] & {
        border-color: $d-border;
      }
      & > span {
        width: 2.1875rem;
        height: 2.1875rem;
        border-radius: 3.125rem;
        display: inline-block;
        padding: 0.4375rem 0.5625rem;
        margin-right: 0.625rem;
        @at-root [direction='rtl']#{&} {
          margin-right: 0;
          margin-left: 0.625rem;
        }
        &.success {
          background: $success-light;
          color: $success;
        }
        &.primary {
          background: var(--rgba-primary-1);
          color: var(--primary);
        }
        &.danger {
          background: $danger-light;
          color: $danger;
        }
      }
      .notify-time {
        width: 100%;
        margin-right: 0;
        color: $l-ctl;
      }
      p {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        max-width: 12.5rem;
        margin-bottom: 0;
        margin-top: 0.3125rem;
        @include respond('phone') {
          max-width: 6.25rem;
        }
      }
    }
    .all-notification {
      display: block;
      padding: 0.9375rem 1.875rem 0;
      // color: $dark;
      text-align: center;
      border-top: 0.0625rem solid $light;

      i {
        margin-left: 0.625rem;
      }
    }
  }
}
.header-profile {
  & > a.nav-link {
    padding: 0;
    display: flex;
    align-items: center;
    margin-left: 0.9375rem;
    i {
      font-weight: 700;
    }
    .header-info {
      padding-left: 0rem;
      text-align: left;
      @include respond('laptop') {
        padding-left: 0.625rem;
      }
      @include respond('phone') {
        display: none;
      }
      span {
        font-size: 1rem;
        color: $black;
        display: block;
        font-weight: 600;
      }
      strong {
        color: $dark;
      }
      small {
        display: block;
        font-size: 0.8125rem;
        color: $muted;
        font-weight: 400;
        line-height: 1.2;
      }
    }
    @include respond('laptop') {
      margin-left: 0rem;
      padding-left: 0rem;
      .header-info {
        span {
          font-size: 1rem;
        }
      }
    }
    @include respond('phone') {
      margin-left: 0rem;
      padding-left: 0rem;
      border-left: 0;
    }
  }
  .dropdown-menu {
    padding: 0.9375rem 0;
    min-width: 12.5rem;
    a {
      &:hover,
      &:focus,
      &.active {
        color: $primary;
      }
    }
  }
  img {
    width: 3.5rem;
    height: 3.5rem;
    border-radius: $radius;
    @include respond('laptop') {
      width: 2.4375rem;
      height: 2.4375rem;
    }
  }
  .dropdown-toggle {
    i {
      font-size: 1.25rem;
    }
    span {
      @include respond('phone') {
        display: none;
      }
    }
  }
  .profile_title {
    background: $primary;
    color: $white;
    padding: 0.625rem 1.25rem;
    h5 {
      color: $white;
      margin-bottom: 0.1875rem;
    }
  }
  // .dropdown-toggle::after{
  //     display: none;
  // }
  .dropdown-item {
    padding: 0.5rem 1.5rem;
  }
}

[data-container='boxed'] {
  .search-area {
    display: none !important;
  }
}
