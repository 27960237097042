.fc-h-event,
.fc-v-event {
  background: var(--primary);
  border-radius: 0.42rem;
}
.fc-h-event .fc-event-title {
  color: $white;
}
.fc-theme-standard td,
.fc-theme-standard th {
  border-color: #ebedf3;
}
.fc-unthemed .fc-h-event,
.fc-unthemed .fc-event-dot {
  padding: 0;
  border-radius: 0.42rem;
}
.fc-theme-standard th {
  padding: 0.75rem 0.5rem;
  font-size: 1rem;
  font-weight: 500;
  color: #b5b5c3;
  @include respond('phone') {
    font-size: 0.875rem;
    font-weight: 400;
    padding: 0.1875rem 0rem;
  }
}
.fc-theme-standard .fc-scrollgrid.fc-scrollgrid-liquid,
.fc-scrollgrid,
table {
  border-color: #ebedf3;
}

.fc-daygrid-dot-event {
  background: #fff;
  border: 0.0625rem solid #ebedf3;
  -webkit-box-shadow: 0 0 0.5625rem 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 0.5625rem 0 rgba(0, 0, 0, 0.1);
  .fc-daygrid-event-dot {
    border-color: var(--primary);
  }
}
.fc-daygrid-dot-event .fc-event-title {
  font-weight: 500;
}
.fc-event.bg-primary,
.fc-event.bg-success,
.fc-event.bg-warning,
.fc-event.bg-secondary,
.fc-event.bg-dark,
.fc-event.bg-info {
  color: $white !important;
  border-radius: 0.5rem;
  .fc-daygrid-event-dot {
    border-color: $white;
  }
}

.fc .fc-scroller-liquid-absolute,
.fc-scroller {
  position: relative;
  overflow: visible !important;
}

.fc .fc-button-group {
  & > .fc-button {
    color: #b5b5c3;
    background: 0 0;
    border: 0.0625rem solid #ebedf3;
    text-shadow: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    &:focus,
    &:active,
    &:hover,
    &.fc-button-active {
      background: var(--primary);
      color: $white;
      border-color: var(--primary);
    }
  }
}
.fc-button.fc-button-primary.fc-today-button {
  background: var(--primary);
  color: $white;
  border: 0;
  opacity: 1;
}
.fc-unthemed .fc-toolbar .fc-button.fc-button-active,
.fc-unthemed .fc-toolbar .fc-button:active,
.fc-unthemed .fc-toolbar .fc-button:focus {
  background: var(--primary);
  color: #fff;
  border: 0.0625rem solid var(--primary);
  -webkit-box-shadow: none;
  box-shadow: none;
  text-shadow: none;
}
.fc .fc-toolbar-title {
  font-size: 1.25rem;
  margin: 0;
}
.fc .fc-toolbar.fc-header-toolbar {
  margin-bottom: -0.5em;
  background: #fff;
  z-index: 2;
  padding: 5px 0;
}

.external-event {
  padding: 0.5rem 0.625rem;
  display: flex;
  align-items: center;
  border-radius: 0.3125rem;
  &:hover {
    &:before {
      background: $white !important;
    }
  }
}
.fc .fc-event {
  overflow: hidden;
  @include respond('phone') {
    margin: 0;
  }
}

.fc .fc-view-harness {
  height: 50rem !important;
  overflow-y: auto;
}
.fc .fc-toolbar.fc-header-toolbar {
  @include respond('phone') {
    display: block;
    .fc-toolbar-chunk {
      display: flex;
      justify-content: center;
      &:first-child {
        justify-content: space-between;
      }
    }
    .fc-toolbar-title {
      margin-bottom: 0.5rem;
    }
  }
}

#external-events {
  .external-event {
    text-align: left;
    font-size: 1rem;
  }
}
