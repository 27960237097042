.review{
	display:flex;
	img{
		width:5rem;
		height:5rem;
		border-radius:1.25rem;
	}
}
.review-button{
	a{
		font-size:0.875rem;
		font-weight:600;
	}	
}
.review-tabs{
	margin-bottom:0.625rem;		
}
.review-tabs-1{
	background:$white;	
	padding: 0 1.25rem;
	border-radius: 1.25rem;
	.nav-tabs{
		border:0;
		padding:0 1.375rem;
		.nav-link{
			margin:0;
			border:0;
			padding: 1rem 1.25rem;
			font-size:1.125rem;
			font-weight:500;
		}
	}
	@include respond ('phone'){
		.nav-tabs{
			padding:0;
			.nav-link{
				padding: 1rem 1rem;
				font-size:1rem;
			}
		}
	}
}

.contacts-list-area{
	.star-review{
		i{
			font-size: 1.25rem;
		}
	}
}